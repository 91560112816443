<template>
  <header class="header unselectable">
    <div class="top-line" v-if="$auth.isAuthenticated">
      <div class="logo-wrapper">
        <a href="/"> <img class="logo-image" src="/img/logo_qi-wide.svg" alt="JMDC QI" /></a>
      </div>
      <div class="top-nav">
        <ul class="nav_menu">
          <li class="submenu">
            <input type="checkbox" id="manualMenu" v-model="openManualMenu" />
            <label class="link_anc" for="manualMenu">JMDC QI マニュアル</label>
            <transition name="fade">
              <div class="nav_child" v-show="openManualMenu">
                <section class="manual-popup-title-line">
                  <div class="manual-popup-title">＞ JMDC QI マニュアル</div>
                  <ul class="menu-item">
                    <li class="subitem">
                      <a target="_blank" href="https://d8kw7niwoz4o3.cloudfront.net/manual/0_1_区分詳細.pdf">区分詳細</a>
                    </li>
                  </ul>
                </section>
                <section v-for="(mainitem, mainindex) in manualMenuList" :key="mainindex">
                  <h3>{{ mainitem.title }}</h3>
                  <ul class="menu-item">
                    <li class="subitem" v-for="(subitem, subindex) in mainitem.items" :key="subindex">
                      <a target="_blank"
                        :class="{ long: subitem.title.length === 10, longlong: subitem.title.length > 10 }"
                        :href="'https://d8kw7niwoz4o3.cloudfront.net' + subitem.link">{{ subitem.title }}</a>
                    </li>
                  </ul>
                </section>
              </div>
            </transition>
          </li>
        </ul>
        <p class="nav-clinic-name">{{ $auth.user['https://bi-ph2.jmdc-qi.com/tableau_name'] }}</p>
      </div>
    </div>
    <div class="top-menu" v-if="$auth.isAuthenticated">
      <p class="nav_ttl">JMDC QI WEB Menu</p>
      <ul class="nav_menu">
        <li class="submenu nav_menu__home"><a class="link_anc" href="/">ホーム</a></li>
        <li class="submenu nav_menu__contents">
          <input type="checkbox" id="openContentsMenu" v-model="openContentsMenu" />
          <label class="link_anc" for="openContentsMenu"
            :style="{ background: openContentsMenu ? '#a6caf0' : '' }">コンテンツ</label>
          <transition name="fade">
            <div class="nav_child" v-show="openContentsMenu">
              <section v-for="(mainitem, mainindex) in menulist" :key="mainindex">
                <h3 class="">{{ mainitem.title }}</h3>
                <ul class="menu-item">
                  <li class="subitem" :class="subitem.allow ? 'allow_subitem' : 'disable_subitem'"
                    v-for="(subitem, subindex) in mainitem.items" :key="subindex">
                    <!-- <a v-if="subitem.allow" :href="'/analysis?category=' + mainitem.title + '&name=' + subitem.title">{{ subitem.title }}</a>
                  <span v-else>{{ subitem.title }}</span> -->
                    <a v-if="subitem.allow && subitem.print_title"
                      :href="'/analysis?category=' + mainitem.title + '&name=' + subitem.title"
                      v-html="subitem.print_title"></a>
                    <a v-if="subitem.allow && !subitem.print_title"
                      :href="'/analysis?category=' + mainitem.title + '&name=' + subitem.title">{{
                        subitem.title
                      }}</a>
                    <span v-if="!subitem.allow && subitem.print_title" v-html="subitem.print_title"></span>
                    <span v-if="!subitem.allow && !subitem.print_title">{{ subitem.title }}</span>
                  </li>
                </ul>
              </section>
            </div>
          </transition>
        </li>
        <li class="submenu nav_menu__inq"><a class="link_anc" target="_blank"
            href="https://forms.gle/mqbg7L78BTWmo9nf9">お問い合わせ</a></li>
        <li class="submenu nav_menu__logout" v-if="$auth.isAuthenticated" @click="openLogoutModal"><a
            class="link_anc">ログアウト</a></li>
      </ul>
    </div>
    <div class="logoutOverlay"
      :style="{ visibility: showLogoutModal ? 'visible' : 'hidden', opacity: showLogoutModal ? '1' : '0' }"
      @click="closeLogoutModal">
      <div class="content">
        <p>ログアウトしますか?</p>
        <div class="button-row">
          <div class="button" @click="closeLogoutModal">いいえ</div>
          <div class="button" @click="logout">ログアウト</div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import _MENU_JSON from '../assets/data/menu.json';
import _MANUAL_MENU_JSON from '../assets/data/manual.json';

export default {
  name: 'Nav',
  data() {
    return {
      manualMenuList: [],
      menulist: [],
      openManualMenu: false,
      openContentsMenu: false,
      selected_user: false,
      showLogoutModal: false,
    };
  },
  created() {
    if (this.$auth.isAuthenticated) {
      let allow_dashboard = this.$auth.user['https://bi-ph2.jmdc-qi.com/allow_dashboard'];
      let allow_dashboard_parts = allow_dashboard.map(function(dashboard) {
            return dashboard.split('$')[0];
      });
      this.menulist = _MENU_JSON.map(function(category) {
        category.items = category.items.map(function(item) {
          console.log('ITEM',item)
          item['allow'] = allow_dashboard.includes(category.title + '$' + item.key);
          return item;
        });
        return category;
      });
      this.manualMenuList = _MANUAL_MENU_JSON.map(function (category) {
        category.items = category.items.map(function (item) {
          return item;
        });
        return category;
      });
    }
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    subMenuClose() {
      this.openContentsMenu = false;
      this.openManualMenu = false;
    },
    openLogoutModal: function () {
      this.showLogoutModal = true;
    },
    closeLogoutModal: function () {
      this.showLogoutModal = false;
    },
  },
  mounted() {
    document.addEventListener('click', this.subMenuClose);
    window.addEventListener('resize', this.getWindowSize);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.subMenuClose);
  },
};
</script>

<style scoped lang="scss">
.header {
  margin: 0 auto;
  width: 100%;

  .nav_menu {
    .link_anc {
      cursor: pointer;
    }

    .nav_child {
      z-index: 100;
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow-y: scroll;
      top: 43px;
      padding: 20px 8px 20px 12px;
      border-radius: 12px;
      color: var(--text-color);

      section {
        h3 {
          font-weight: normal;
          margin: 0;
          margin-left: 20px;
        }

        .menu-item {
          display: flex;
          flex-wrap: wrap;
          gap: 6px 4px;
          list-style: none;
          margin: 0;
          padding: 0;

          .subitem {
            display: flex;
            align-content: center;
            margin: 0;
            padding: 0;
            width: 242px;
            border-radius: 12px;
            transition: all 0.5s ease-out;

            &:hover {
              border-radius: 20px;
            }

            a,
            span {
              line-height: 1.2;
              text-decoration: none;
              width: 100%;
              padding: 6px 0;
              padding-left: 14px;
              letter-spacing: 3px;
              font-size: 18px;

              &.long {
                letter-spacing: 2px;
              }

              &.longlong {
                letter-spacing: 0;
              }
            }
          }
        }
      }
    }
  }

  .top-line {
    display: flex;
    height: 80px;
    justify-content: flex-end;
    width: 100%;

    .logo-wrapper {
      align-self: flex-end;
      width: 232px;

      .logo-image {
        display: block;
        width: 100%;
      }
    }

    .top-nav {
      background: linear-gradient(0deg, #4da635 10px, #4c9538 50%, #4da635 50%);
      color: #fff;
      flex-grow: 1;
      padding: 7px 20px;
      position: relative;
      text-align: right;

      &::before {
        border-bottom: 80px solid #fff;
        border-left: 40px solid #fff;
        border-right: 40px solid transparent;
        border-top: 80px solid #fff;
        content: '';
        display: inline-block;
        height: 0;
        left: 0;
        position: absolute;
        top: -50%;
        width: 0;
      }

      .nav_menu {
        display: inline-block;
        margin: 0;
        padding: 0;

        .submenu {
          align-items: center;
          display: flex;
          width: auto;
          flex-direction: column;
          flex-shrink: 1;
          position: relative;

          .link_anc::before {
            content: '?';
            display: inline-block;
            font-size: 18px;
            color: #4da635;
            background: #fff;
            height: 25px;
            width: 27px;
            text-align: center;
            padding-top: 2px;
            border-radius: 50%;
            margin-right: 6px;
          }

          .nav_child {
            height: 680px;
            left: -581px;
            width: 780px;
            background-color: #f0faed;
            border: 2px solid #4c9b36;
            text-align: left;

            @media(max-width: 1360px) {
              width: 770px;
              left: -570px;
            }

            @media(max-width: 1024px) {
              width: 800px;
              left: -600px;
            }

            &::before {
              content: '';
              display: inline-block;
              height: 25px;
              left: 1056px;
              top: -12px;
              width: 25px;
              transform: rotate(45deg);
              background: #f0faed;
            }

            section {
              &.manual-popup-title-line {
                display: flex;
                justify-content: space-between;
                margin: 0 0 24px 24px;

                .manual-popup-title {
                  font-size: 1.3em;
                  border-bottom: 2px solid #4c9a37;
                  width: 40%;
                }

                .menu-item {
                  top: 18px;
                  position: relative;
                }
              }

              .subitem {
                background-color: var(--regincl-color);
                cursor: pointer;

                a {
                  color: #fff;
                }

                &:hover {
                  background-color: #3fe910;

                  a {
                    color: var(--text-color);
                  }
                }
              }
            }
          }
        }
      }

      .nav-clinic-name {
        font-size: 2.8rem;
        font-weight: normal;
        margin: 10px 0 0;
        padding: 0;
      }
    }
  }

  .top-menu {
    align-items: center;
    display: flex;
    font-size: 1.8rem;
    justify-content: flex-end;
    margin-top: 30px;
    position: relative;

    @media (max-width: 950px) {
      flex-direction: column;
    }

    .nav_ttl {
      color: #999;
      margin: 0 40px 0 0;
      padding: 0;

      @media (max-width: 950px) {
        margin-right: 510px;
        justify-content: flex-end;
      }
    }

    .nav_menu {
      display: flex;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid #b3b3b3;

      @media (max-width: 950px) {
        margin-right: -50px;
        justify-content: flex-end;
      }

      .submenu {
        position: relative;

        .link_anc {
          align-items: center;
          display: flex;
          justify-content: center;
          height: 100%;
          width: 10em;
          padding: 5px 5px 2px;
          text-align: center;

          &::before {
            height: 32px;
            width: 34px;
            display: block;
            margin-right: 10px;
            padding: 4px 0 0;
          }

          &:hover {
            background: #a6caf0;

            &::before {
              background: #fff;
              border-radius: 50%;
            }
          }
        }

        &.nav_menu__home>.link_anc::before {
          content: url('/img/ico_gnav-home.svg');
        }

        &.nav_menu__user>.link_anc::before {
          content: url('/img/ico_gnav-user.svg');
        }

        &.nav_menu__contents>.link_anc::before {
          content: url('/img/ico_gnav-cont.svg');
        }

        &.nav_menu__inq>.link_anc::before {
          content: url('/img/ico_gnav-inq.svg');
        }

        &.nav_menu__logout>.link_anc::before {
          content: url('/img/ico_gnav-logout.svg');
        }

        &:not(:last-of-type)>.link_anc::after {
          content: '';
          position: absolute;
          vertical-align: middle;
          height: 80%;
          right: -2px;
          bottom: 4px;
          border-left: 1px solid #b3b3b3;
          width: 1px;
        }

        .nav_child {
          height: 1018px;
          background-color: #a6caf0;
          border: 2px solid #2b94ff;
          width: 1252px;
          text-align: left;
          left: -713px;

          @media(max-width: 1360px) {
            width: 1005px;
            left: -470px;
            text-align: left;
          }

          @media(max-width: 1024px) {
            width: 763px;
            left: -228px;
            text-align: left;
          }


          section {
            .subitem {
              &.allow_subitem {
                background-color: #dfefff;
                cursor: pointer;

                a {
                  color: var(--text-color);
                }

                &:hover {
                  background-color: #2b94ff;

                  a {
                    color: white;
                  }
                }
              }

              &.disable_subitem {
                background-color: #d9d9d9;

                span {
                  color: #4e4e4e;
                }
              }
            }
          }
        }
      }
    }
  }

  .logoutOverlay {
    z-index: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.25s linear;

    .content {
      z-index: 500;
      width: 380px;
      height: 170px;
      background: #fff;
      border-radius: 5px;
      position: relative;
      text-align: center;
      padding-top: 36px;

      p {
        display: flex;
        justify-content: center;

        &::before {
          content: url('/img/ico_gnav-logout.svg');
          margin-right: 18px;
          display: block;
          margin-top: -4px;
        }
      }

      .button-row {
        position: absolute;
        bottom: 0;
        flex-direction: row;
        display: flex;
        cursor: pointer;

        .button {
          width: 190px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:first-child {
            background-color: #f7f7f7;
            border-radius: 0 0 0 5px;
          }

          &:last-child {
            background-color: #4da635;
            color: #fff;
            border-radius: 0 0 5px 0;
          }
        }
      }
    }
  }
}
</style>
