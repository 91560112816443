<template>
  <div style="height:100vh;display: flex;" v-if="$auth.loading">
    <vue-loading type="bars" color="#4ca135" :size="{ width: '70px', height: '70px' }"></vue-loading>
  </div>
  <div v-else id="app" :class="[$auth.isAuthenticated ? 'page_contents' : 'page_login']">
    <Nav />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template';
import Nav from './components/Nav.vue';
import Footer from './components/Footer.vue';
export default {
  name: 'app',
  components: {
    VueLoading,
    Nav,
    Footer,
  },
};
</script>
<style scoped>
#app {
  display: flex;
  flex-direction: column;
  line-height: 1.4;
  margin: auto;
  min-height: 100vh;
  /* padding: 0 1vw; */
  position: relative;
  vertical-align: baseline;
  width: 100%;
  max-width: var(--max-width);
  min-width: var(--min-width);
  overflow: hidden; 
}
</style>
