<template>
  <main class="main login" id="main" v-if="auth0Status() == 'notLogin'">
    <div class="col_logo">
      <h1><img class="img_logo" src="/img/logo_qi-rect.svg" alt="JMDC QI" /></h1>
      <h2>JMDC QI WEB / Login Page</h2>
    </div>
    <div class="col_txt">
      <a class="link_next" @click="login">ログインページへ</a>
    </div>
  </main>

  <main class="main" id="main" v-else-if="auth0Status() == 'normalLogin'">
    <div class="col_info">
      <h2>お知らせ</h2>
      <dl class="lst_info scroll">
        <template v-for="(item, index) in news">
          <dt :key="index">{{ item.date }}</dt>
          <dd :key="`${index}-key`" v-html="item.news"></dd>
        </template>
      </dl>
    </div>
    <div class="col_side">
      <h2>コンテンツ選択</h2>
      <ul class="nav_side unselectable">
        <template v-for="(mainitem, mainindex) in menulist">
          <li class="submenu" :class="'nav_side__' + mainitem.classname" v-if="mainitem.allow" :key="mainindex">
            <label class="lnav_submenu_label" :for="'submenu__' + mainitem.classname"></label>
            <input type="radio" name="lnav_submenu" :id="'submenu__' + mainitem.classname"
              :value="'submenu__' + mainitem.classname" v-model="selected" />
            <div class="link_anc-wrapper">
              <label class="link_anc">{{ mainitem.title }}</label>
            </div>
            <div class="nav_child">
              <h3 class="nav_child_title">{{ mainitem.title }}</h3>
              <ul class="menu-item">
                <div v-for="(subitem, subindex) in mainitem.items" :key="subindex">
                  <li class="subitem" :class="subitem.allow ? 'allow_subitem' : 'disable_subitem'" v-if="subitem.allow">
                    <a v-if="subitem.allow" :href="'/analysis?category=' + mainitem.title + '&name=' + subitem.title">{{
                      subitem.title }}</a>
                    <span v-else>{{ subitem.title }}</span>
                  </li>
                </div>
              </ul>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </main>

  <main v-else>
    <vue-loading type="bars" color="#d9544e" :size="{ width: '70px', height: '70px' }"></vue-loading>
  </main>
</template>

<script>
import { VueLoading } from 'vue-loading-template';
import _MENU_JSON from '../assets/data/menu.json';

export default {
  name: 'home',
  components: { VueLoading },
  data() {
    return {
      news: [],
      menulist: [],
      selected: false,
    };
  },
  async created() {
    if (this.auth0Status() == 'normalLogin') {
      let allow_dashboard = this.$auth.user['https://bi-ph2.jmdc-qi.com/allow_dashboard'];
      let allow_dashboard_parts = allow_dashboard.map(function(dashboard) {
            return dashboard.split('$')[0];
        });
      this.menulist = _MENU_JSON.map(function(category) {
        category['allow'] = false;
        category.items = category.items.map(function (item) {
          item['allow'] = false;
          if (allow_dashboard_parts.includes(category.title)) {
            category['allow'] = true;
            item['allow'] = true;
          }
          return item;
        });
        return category;
      });
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    auth0Status() {
      let ret;
      if (!this.$auth.loading && !this.$auth.isAuthenticated) {
        ret = 'notLogin';
      } else if (!this.$auth.loading && this.$auth.isAuthenticated) {
        ret = 'normalLogin';
      } else {
        ret = 'lodding';
      }
      return ret;
    },
    subMenuClose() {
      this.selected = false;
    },
  },
  mounted() {
    /*window.addEventListener('load', async () => {
      await this.$auth.getUser();
    });*/
    let self = this;
    fetch('/news.tsv').then(function (response) {
      let result = [];
      response.text().then(function (text) {
        let lines = text.split(/\r\n|\n/);
        let headers = ['date', 'news'];
        for (let i = 0; i < lines.length; i++) {
          let obj = {};
          let currentline = lines[i].split(/\t/);
          if (currentline[0]) {
            obj[headers[0]] = currentline[0];
            obj[headers[1]] = currentline[1];
            result.push(obj);
          }
        }
      });
      self.news = result;
    });

    document.addEventListener('click', this.subMenuClose);
  },
  updated() {
    if (this.$auth.isAuthenticated) {
      document.body.className = 'page_contents';
    } else {
      document.body.className = 'page_login';
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.subMenuClose);
  },
};
</script>

<style scoped lang="scss">
.main{
  margin-bottom: 0px;
  // @media (min-width: 1360px) {
  //   margin-bottom: 400px;
  // }
}
.vue-loading {
  color: #4da635;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.login {
  .col_logo {
    margin-top: -40px;

    h2 {
      font-size: 3.2rem;
    }

    .img_logo {
      width: 410px;
    }
  }

  .col_txt {
    margin-top: -40px;
    width: 360px;

    .link_next {
      font-size: 2rem;
      padding: 20px 30px;
      color: #fff;
      background: #e66446;
      border: 1px solid #e66446;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background: #fff;
        color: #e66446;
      }
    }
  }
}

.col_info {
  background: #f0faed;
  border: 1px solid #daecd5;
  margin: 0 auto 24px;
  overflow: hidden;
  width: 100%;

  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    align-items: center;
    color: #fff;
    background: #4da635;
    margin: auto;
    padding: 2px 10px;
    text-align: center;
  }

  .lst_info {
    display: flex;
    flex-wrap: wrap;
    margin: 10px auto 10px;
    max-height: 85px;
    height: 85px;
    max-width: 1000px;
    overflow-y: scroll;
    padding: 0 30px 0 0;

    dt {
      color: #4da635;
      min-width: 120px;
    }

    dd {
      margin-left: 0;
      max-width: 1110px;
      padding-bottom: 8px;
      width: 86%;

      ::v-deep {

        a,
        a:link,
        a:visited,
        a:hover,
        a:active,
        a:focus {
          color: var(--regincl-color);
        }
      }
    }
  }

  .scroll {
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--regincl-color);
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #daecd5;
      border-radius: 6px;
    }
  }
}

.col_side {
  position: relative;
  width: 100%;
  min-height: 824px;

  h2 {
    background: #ebebe0;
    box-shadow: 5px 5px 5px rgba(23, 23, 23, 0.05) inset, -5px -5px 5px rgba(23, 23, 23, 0.05) inset;
    font-size: 2.6rem;
    font-weight: bold;
    align-items: center;
    margin: 10px auto 24px;
    padding: 2px 10px;
  }

  .nav_side {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1360px) {
      width: 1200px;
    }

    @media (max-width: 1200px) {
      width: 1100px;
    }

    @media (max-width: 1024px) {
      width: 900px;
    }

    .submenu {
      transition: transform 0.3s linear;

      @media (min-width: 1360px) {
        position: absolute;
      }

      margin-right:35px;
      margin-bottom: 50px;

      .lnav_submenu_label {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        cursor: pointer;
      }

      .link_anc-wrapper {
        display: flex;
        border-radius: 10px;
        height: 150px;
        width: 150px;
        padding: 12px;
        transition: transform 0.3s linear;

        .link_anc {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #fff;
          font-size: 1.8rem;
          line-height: 1.2;
          width: 100%;
          padding: 5px 5px 10px;
          border: 3px solid #fff;
          border-radius: 10px;

          &::before {
            content: '';
            display: block;
            background-position: center center;
            background-repeat: no-repeat;
            margin: 5px auto auto;
            min-height: 70px;
            width: 100%;
          }
        }
      }

      &:hover {
        .link_anc-wrapper {
          filter: saturate(200%) drop-shadow(5px 5px 15px #aaa);
          transform: scale(1.03);
        }
      }

      &:nth-child(1) {
        left: 0;

        .nav_child {
          left: 6px;

          &::after {
            left: 63px;
          }
        }
      }

      &:nth-child(2) {
        left: calc((150px + 34px) * 1);

        .nav_child {
          left: -120px;

          @media (max-width: 1360px) {
            left: 20px;
          }

          &::after {
            left: 182px;

            @media (max-width: 1360px) {
              left: 226px;
            }
          }
        }
      }

      &:nth-child(3) {
        left: calc((150px + 34px) * 2);

        .nav_child {
          left: -92px;

          @media (max-width: 1360px) {
            left: 35px;
          }

          &::after {
            left: 158px;

            @media (max-width: 1360px) {
              left: 390px;
            }
          }
        }
      }

      &:nth-child(4) {
        left: calc((150px + 34px) * 3);

        .nav_child {
          left: -130px;

          @media (max-width: 1360px) {
            left: 394px;
          }

          @media (max-width: 1200px) {
            left: 212px;
          }

          @media (max-width: 1024px) {
            left: 20px;
          }

          &::after {
            left: 194px;

            @media (max-width: 1360px) {
              left: 221px;
            }

            @media (max-width: 1200px) {
              left: 395px;
            }

            @media (max-width: 1024px) {
              left: 592px;
            }
          }
        }
      }

      &:nth-child(5) {
        left: calc((150px + 34px) * 4);

        .nav_child {
          left: -166px;

          @media (max-width: 1360px) {
            left: 394px;
          }

          @media (max-width: 1200px) {
            left: 212px;
          }

          @media (max-width: 1024px) {
            top: 365px;
            left: 0px;
          }

          &::after {
            left: 232px;

            @media (max-width: 1360px) {
              left: 402px;
            }

            @media (max-width: 1200px) {
              left: 588px;
            }

            @media (max-width: 1024px) {
              left: 63px;
            }
          }
        }
      }

      &:nth-child(6) {
        left: calc((150px + 34px) * 5);

        .nav_child {
          left: -350px;

          @media (max-width: 1360px) {
            left: 400px;
          }

          @media (max-width: 1200px) {
            left: 0px;
            top: 365px;
          }

          @media (max-width: 1024px) {
            top: 365px;
            left: 14px;
          }

          &::after {
            left: 412px;

            @media (max-width: 1360px) {
              left: 576px;
            }

            @media (max-width: 1200px) {
              left: 63px;
            }

            @media (max-width: 1024px) {
              left: 230px;
            }
          }
        }
      }

      &:nth-child(7) {
        left: calc((150px + 34px) * 6);

        .nav_child {
          left: -534px;


          @media (max-width: 1360px) {
            left: 0px;
            top: 365px;
          }

          @media (max-width: 1200px) {
            left: 20px;
            top: 365px;
          }

          @media (max-width: 1024px) {
            top: 365px;
            left: 35px;
          }

          &::after {
            left: 594px;

            @media (max-width: 1360px) {
              left: 63px;
            }
            @media (max-width: 1200px) {
            left: 226px;
          }

            @media (max-width: 1024px) {
              left: 390px;
            }

          }
        }
      }

      .nav_child {
        position: absolute;
        width: 680px;
        opacity: 0;
        visibility: hidden;
        transition: all 1s ease-out;
        border-radius: 10px;
        /*bottom: 166px;*/
        top: 168px;
        height: auto;
        margin: 0;
        padding: 14px 10px 18px;
        overflow: visible;
        z-index: 1;

        .nav_child_title {
          color: #fff;
          margin: 0 auto 2px;
          padding: 0 5px;

          &::before {
            margin-right: 10px;
            vertical-align: middle;
          }
        }

        .menu-item {
          display: flex;
          flex-wrap: wrap;
          height: 100%;
          list-style: none;
          margin: 0;
          padding: 0;

          div {
            display: contents;

            .subitem {
              min-width: 10em;
              padding: 0;
              margin: 3px 2px;
              background: #fff;
              border-radius: 10px;

              &.allow_subitem {
                transition: all 0.8s;
                border: 1px solid #fff;

                &:hover a {
                  color: #fff;
                }
              }

              &.disable_subitem {
                background: #e0e0e0;
              }

              a {
                padding: 8px;
                display: block;
              }
            }
          }
        }
      }

      input:checked {
        ~.nav_child {
          opacity: 1;
          visibility: visible;
          position: absolute;
          z-index: 999;

          &::after {
            content: '';
            display: inline-block;
            height: 25px;
            position: absolute;
            /*bottom: -10px;*/
            top: -12px;
            width: 25px;
            transform: rotate(45deg);
          }
        }
      }
    }

    .nav_side__hospn {
      .link_anc-wrapper {
        background-color: var(--hospn-color);

        .link_anc::before {
          background-image: url('/img/ico_lnav-hospn.svg');
        }
      }

      .nav_child {
        background-color: var(--hospn-color);

        a {
          color: var(--hospn-color);
        }

        &::after,
        .subitem.allow_subitem:hover {
          background-color: var(--hospn-color);
        }
      }
    }

    .nav_side__opd {
      .link_anc-wrapper {
        background-color: var(--opd-color);

        .link_anc::before {
          background-image: url('/img/ico_lnav-opd.svg');
        }
      }

      .nav_child {
        background-color: var(--opd-color);

        a {
          color: var(--opd-color);
        }

        &::after,
        .subitem.allow_subitem:hover {
          background-color: var(--opd-color);
        }
      }
    }

    .nav_side__regincl {
      .link_anc-wrapper {
        background-color: var(--regincl-color);

        .link_anc::before {
          background-image: url('/img/ico_lnav-regincl.svg');
        }
      }

      .nav_child {
        background-color: var(--regincl-color);

        a {
          color: var(--regincl-color);
        }

        &::after,
        .subitem.allow_subitem:hover {
          background-color: var(--regincl-color);
        }
      }
    }

    .nav_side__rehab {
      .link_anc-wrapper {
        background-color: var(--rehab-color);

        .link_anc::before {
          background-image: url('/img/ico_lnav-rehab.svg');
        }
      }

      .nav_child {
        background-color: var(--rehab-color);

        a {
          color: var(--rehab-color);
        }

        &::after,
        .subitem.allow_subitem:hover {
          background-color: var(--rehab-color);
        }
      }
    }

    .nav_side__lrd {
      .link_anc-wrapper {
        background-color: var(--lrd-color);

        .link_anc::before {
          background-image: url('/img/ico_lnav-lrd.svg');
        }
      }

      .nav_child {
        background-color: var(--lrd-color);

        a {
          color: var(--lrd-color);
        }

        &::after,
        .subitem.allow_subitem:hover {
          background-color: var(--lrd-color);
        }
      }
    }

    .nav_side__safmgt {
      .link_anc-wrapper {
        background-color: var(--safmgt-color);

        .link_anc::before {
          background-image: url('/img/ico_lnav-safmgt.svg');
        }
      }

      .nav_child {
        background-color: var(--safmgt-color);

        a {
          color: var(--safmgt-color);
        }

        &::after,
        .subitem.allow_subitem:hover {
          background-color: var(--safmgt-color);
        }
      }
    }

    .nav_side__yakuzai {
      .link_anc-wrapper {
        background-color: var(--yakuzai-color);

        .link_anc::before {
          background-image: url('/img/ico_lnav-yakuzai.svg');
        }
      }

      .nav_child {
        background-color: var(--yakuzai-color);

        a {
          color: var(--yakuzai-color);
        }

        &::after,
        .subitem.allow_subitem:hover {
          background-color: var(--yakuzai-color);
        }
      }
    }
  }
}
</style>
